<template>
  <div class="wrapp-choose-language-rightnav right-block-panel">
    <div class="right-block-content">
      <div class="wrapp-select-language-rightnav">
        <div class="shipping-selector">
          <p class="content-title">{{ $t('shipping-to') }}:</p>
          <div class="wrapp-ctas">
            <div class="current-shipping-country">
                <span class="bcm-link bcm-link-primary">{{ currentCountry.country_name }}</span>
            </div>
            <div class="cta-ch-country">
              <button class="btn btn-none bcm-link bcm-link-secondary" @click.prevent="openChCountry">{{ $t('chooseCountry.ch-country-region') }}</button>
            </div>
          </div>
        </div>
        <div class="language-selector">
          <p class="content-title">{{ $t('language') }}:</p>
          <div class="wrapp-language">
            <ul class="nav flex-column">
              <li v-for="locale in currentCountry.locales" :key="locale.store_view">
                <a :href="redirectLocale(locale.store_view)" class="ch-country-link state-link bcm-link bcm-link-secondary" :class="{active : locale.store_view === currentStoreView}" v-t="`site-country.${locale.language}`" @click="setLocations(locale.store_view)"></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChooseLanguage from '../../Language/ChooseLanguage.vue'
export default {
  mixins: [ChooseLanguage],
  methods: {
    openChCountry () {
      const componentToLoad = import('./CountriesList')
      this.$store.dispatch('ui/loadActiveComponent', { component: componentToLoad })
    },
    setLocations (storeView) {
      global.$cookies.set('country-choice', storeView, '30d')
    }
  }
}
</script>
